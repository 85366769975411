import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import { Cubes, H2, Medailon, PaddingWrapper } from "@igloonet-web/shared-ui"
import Logo from "../../../images/reference/jena/logojena.png"
import Screenshot from "../../../images/reference/jena/wireframe1.png"
import Heart from "../../../images/reference/jena/design.png"
import BottomBanner from "../../../images/reference/jena/bottom-banner.png"
import Beranek from "../../../images/reference/jena/jena-beranek.png"
import David from "../../../images/team/mini/david.jpg"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"

class Jena extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>
            Nastavení a úprava kampaní pro předání internímu týmu - JENA nábytek
            | igloonet
          </title>
          <meta
            name="description"
            content="Klient stavěl interní tým, potřeboval zefektivnit kampaně a postupně je začít přebírat. Co byli první kroky, co jsme po převzetí účtů po agentuře dělali a jak jsme postupovali k předání internímu týmu"
          />
        </Helmet>

        <ReferenceHeader
          companyName="JENA"
          heading="Nastavili a upravili jsme kampaně pro předání internímu týmu"
          date="prosinec 2017 - únor 2018"
        />

        <ReferenceImageText
          halfImgSize
          right
          img={Screenshot}
          eager
          alt=""
          logo={Logo}
          logoAlt="logo JENA"
          link="//jena-nabytek.cz/"
        >
          <h2>Co nás čekalo</h2>
          <p>
            Jena nábytek je přední offline a online prodejce kompletního
            sortimentu nábytku a bytových doplňků v ČR a SR.
          </p>
          <Cubes
            bgWhite
            data={[
              {
                description: "E-shop",
                number: "1",
              },
              {
                description: "Prodejen",
                number: "16",
              },
              {
                description: "Kusů nábytku",
                number: "52 000+",
              },
            ]}
          />
        </ReferenceImageText>

        <PaddingWrapper>
          <ReferenceImageText>
            <H2>Cíle spolupráce</H2>
            <p>
              Vylepšení stávajících kampaní v Google Ads a Skliku tak, aby
              generovaly větší tržby bez navyšování PNO
            </p>
            <ul>
              <li>Maximalizovat tržby při udržení PNO</li>
              <li>Vylepšit a rozšířit stávající online kampaně</li>
              <li>Nasadit automatizace</li>
              <li>
                Vše udělat tak srozumitelné, aby kampaně mohl převzít interní
                tým
              </li>
            </ul>
          </ReferenceImageText>
        </PaddingWrapper>

        <PaddingWrapper>
          <PaddingWrapper>
            <Medailon
              personName="David Pohorský"
              position="senior PPC specialista"
              img={David}
            >
              <p>
                Původní kampaně nebyly v dobré kondici, nasměrovali jsme je
                správným směrem a předali je internímu týmu.
              </p>
            </Medailon>
          </PaddingWrapper>
        </PaddingWrapper>

        <ReferenceImageText img={Heart} alt="">
          <H2>Úvodní audit</H2>
          <p>
            Od začátku jsme počítali s krátkodobou spoluprací - v JENĚ vznikal
            inhouse marketingový tým a naším úkolem bylo vše vylepšít a
            nachystat na předání. Úvodním auditem jsme zjistili, že kampaně jsou
            ve velmi špatném stavu, například:
          </p>
          <ul>
            <li>
              Struktura kampaní byla příliš jednoduchá a nedovolovala efektivní
              správu a vyhodnocování.
            </li>
            <li>
              Klíčová slova měla nízké skóre kvality, to se projevilo ve vyšší
              ceně za proklik.
            </li>
            <li>Reklamní texty měly nízké pokrytí klíčovými slovy.</li>
            <li>
              Kvůli špatné struktuře a vylučování měly vysoké procento
              duplicitních vyhledávacích dotazů.
            </li>
            <li>Automatizace se využívaly jen v omezené míře.</li>
          </ul>
        </ReferenceImageText>

        <ReferenceImageText>
          <H2>Průběh spolupráce</H2>
          <ul>
            <li>
              Vzhledem ke krátkodobé spolupráci jsme nemohli pracovat s celým
              účtem. Vydefinovali jsme nejdůležitější (nejziskovější) kategorie
              nábytku a zjistili jejich potenciál hledanosti. Na jeho základě
              jsme s klientem vytvořili harmonogram, podle kterého se budou
              kampaně v průběhu roku vytvářet. Z velké části záleželo na
              hledanosti, ale do rozhodování vstupovaly i další faktory jako
              např. skladovost, marže, sezónnost nebo naplánované akce.
            </li>
            <li>
              Stávající kampaně měly příliš jednoduchou strukturu, takže jsme se
              rozhodli vytvořit vše od znova. Pokryli jsme více URL a
              vyhledávacích dotazů, na které se předtím necílilo, a počtem
              kampaní i reklamních sestav jsme původní počet několikanásobně
              navýšili. V celém účtu jsme také zavedli jednotný sytém
              pojmenování, což vše zpřehlednilo a umožnilo efektivnější správu.
            </li>
            <li>
              V igloo se hodně zaměřujeme na automatizace, takže naše další
              kroky vedly tímto směrem. Za prvé jsme v API Google Ads vytvořili
              skript, který v reklamních textech automaticky aktualizuje
              dynamické informace jako např. minimální cenu, maximální slevu
              nebo počet kusů v nabídce. Výhodou řešení přes API je mimo jiné
              vyšší stabilita než u dočasného řešení přes Google Ads skripty.
              Dále jsme nasadili Ads.care, náš nástroj na vypínání reklamních
              sestav na základě požadované skladovosti. Šetří peníze tím, že
              necílí na vyprodané kategorie nebo kategorie s nízkým počtem
              produktů. A do třetice jsme připravili vlastní API skript na
              automatickou tvorbu sitelinků.
            </li>
          </ul>
        </ReferenceImageText>

        <PaddingWrapper>
          <img
            src={BottomBanner}
            alt=""
            className="img-fluid d-flex mx-auto"
            loading="lazy"
          />
        </PaddingWrapper>

        <PaddingWrapper>
          <ReferenceImageText>
            <H2>Shrnutí spolupráce</H2>
            <p>
              OKAY Holdingu jsme pomohli s restartem a výrazným rozšířením
              kampaní eshopu JENA nábytek. Zavedli jsme jednotný systém
              pojmenování a celou strukturu logicky zpřehlednili, což umožňuje
              mnohem efektivnější a intuitivnější správu kampaní. Novému týmu
              jsme tak vytvořili ideální startovní podmínky.
            </p>
          </ReferenceImageText>
        </PaddingWrapper>

        <PaddingWrapper>
          <Medailon
            personName="Tomáš Beránek"
            position="CMO OKAY Holding"
            img={Beranek}
          >
            <p>
              Naše spolupráce byla krátká, ale velice prospěšná. S čistým
              svědomím mohu igloonet doporučit.
            </p>
          </Medailon>
        </PaddingWrapper>
        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default Jena
